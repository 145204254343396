
import { defineComponent, onMounted } from "vue";
import FinancialScoring from "@/components/FinancialScoring.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewRecomendation from "@/components/modals/NewRecomendation.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "documents",
  components: {
    // NewRecomendation,
    ElCurrencyInput,
  },

  data() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.id ?? null;
    const fin_id = route.params.fin_id ?? null;

    const ratios = {
      result_standby_liquidity_ratio: {
        mark1: {
          analysis_0: "Your score of",
          analysis_1:
            "months means that, without income, your liquid investment assets that can be converted into cash are able to cover your living expenses for another 12.69 months after your cash savings for",
          analysis_2:
            "months (Basic Liquidity Ratio) have been used up. This means that your cash reserve and liquid investment assets can last for a total of",
          analysis_3:
            "months (Standby Liquidity Ratio + Basic Liquidity Ratio) to cover your living expenses.",
          recommendation:
            "<span>You must keep track of your expenses, spend prudently within your means, and continue to allocate funds to acquire liquid investment assets to cover for any unexpected expenses.</span>",
        },
        mark2: {
          analysis_0: "Your score of",
          analysis_1:
            "months means that, without income, your liquid investment assets that can be converted into cash are able to cover your living expenses for another 4 months after your cash savings for",
          analysis_2:
            "months (Basic Liquidity Ratio) have been used up. This means that your cash reserve and liquid investment assets can only last for a total of",
          analysis_3:
            "months (Standby Liquidity Ratio + Basic Liquidity Ratio) to cover your living expenses.",
          recommendation:
            "<span>You should increase income and savings so that you are able to acquire more liquid investment assets to meet unexpected expenses, and to spend prudently on necessities only and not wants.</span>",
        },
        mark3: {
          analysis_0: "Your score of",
          analysis_1:
            "months means that, without income, your liquid investment assets that can be converted into cash are able to cover your living expenses for another 2 months after your cash savings for",
          analysis_2:
            "months (Basic Liquidity Ratio) have been used up. This means that your cash reserve and liquid investment assets can only last for a total of",
          analysis_3:
            "months (Standby Liquidity Ratio + Basic Liquidity Ratio) to cover your living expenses.",
          recommendation:
            "<span>You should increase income and savings so that you are able to acquire liquid investment assets to meet unexpected expenses, and to spend prudently on necessities only and not wants.</span>",
        },
      },
      result_saving_ratio: {
        mark1: {
          analysis:
            "You have a good savings ratio of 20.08% which is above the recommended minimum of 10% of your personal income.",
          recommendation:
            "<span>You must keep track of your expenses, spend prudently within your means, and continue to allocate funds to acquire liquid investment assets to cover for any unexpected expenses.</span>",
        },
        mark2: {
          analysis: "No parameter under Average.",
          recommendation: "<span>No parameter under Average.</span>",
        },
        mark3: {
          analysis:
            "Your savings ratio of 5% means that you have insufficient savings as it is below the recommended minimum of 10% of your personal income. ",
          recommendation:
            "<span>You should increase income and savings to meet unexpected expenses, spend prudently on necessities and wants, and reduce unnecessary expenses.</span>",
        },
      },
      result_liquid_assets: {
        mark1: {
          analysis:
            "Your score of 16.67% means that your liquid asset composition is sufficient because it is above the minimum requirement of 15% to have sufficient liquidity to cover for unexpected expenses or crisis.",
          recommendation:
            "<span>You must keep track of your expenses, spend prudently so that you stay within your means,  and maintain and continue to allocate funds to acquire liquid investment assets to cover any unexpected expenses.</span>",
        },
        mark2: {
          analysis: "No parameter under Average.",
          recommendation: "<span>No parameter under Average.</span>",
        },
        mark3: {
          analysis:
            "Your score of 10% means that your liquid asset composition is insufficient because it is below the minimum requirement of 15% to have sufficient liquidity to cover for unexpected expenses or crisis.",
          recommendation:
            "<span>You should increase income and savings so that you are able to acquire liquid investment assets to meet unexpected expenses, and to spend prudently on necessities only and not wants.</span>",
        },
      },
      result_debt: {
        mark1: {
          analysis:
            "Your score of 30% means that you have more than sufficient assets to be liquidated if any incident were to happen which requires you to sell all your assets to pay off all of your debts.",
          recommendation:
            "<span>You must keep track of your expenses, spend prudently so that you stay within your means,  and maintain and continue to allocate funds to acquire liquid investment assets to meet expenses in the event of an emergency so that there is no need to obtain financing or  loan to meet these unexpected expenses in order to maintain low debt ratio. You must practice  cash management and budgeting.</span>",
        },
        mark2: {
          analysis:
            "Your score of 45 percent means that you have slightly more than enough assets to liquidate if any incident were to happen which requires you to sell all your assets to pay off all of your debts.",
          recommendation:
            "<span>You should increase income and funds/savings to enable you to increase your debt instalment payments or to make early settlement as this will reduce your total debts. You should spend prudently within your means and practice cash management, budgeting and debt management. </span>",
        },
        mark3: {
          analysis:
            "Your score of 60% means that you have insufficient assets to be liquidated if any incident were to happen which requires you to sell all your assets to pay off all of your debts.",
          recommendation:
            "<span>You should increase income and funds/savings to enable you to increase your debt instalment payments or to make early settlement as this will reduce your total debts. You should spend prudently on necessities only and not wants, and practice  cash management, budgeting and debt management. </span>",
        },
      },
      result_debt_service_ratio: {
        mark1: {
          analysis:
            "Your score of 35% means that you are using not more than the recommended limit of 40% of your net income to pay for your monthly debt commitments.",
          recommendation:
            "<span>You must keep track of your expenses, spend prudently within your means, and continue to allocate funds to acquire liquid investment assets to meet expenses in the event of an emergency so that there is no need to obtain financing or loan to meet these unexpected expenses in order to maintain low debt ratio. </span>",
        },
        mark2: {
          analysis:
            "Your score of 50% means that you are using more than the recommended limit of 40% of your net income to pay for your monthly debt commitments.",
          recommendation:
            "<span>You should increase income and funds/savings to enable you to increase your debt instalment payments or to make early settlement to reduce future debt commitments. You can request  from your bank or AKPK for a reduction in your monthly debt payments to free up more cash for living expenses.  You should spend prudently within your means and practice cash management, budgeting and debt management.</span>",
        },
        mark3: {
          analysis:
            "Your score of 70% means that you are using more than the recommended limit of 40% of your net income to pay for your monthly debt commitments.",
          recommendation:
            "<span>You should increase income and funds/savings to enable you to increase your debt instalment payments or to make early settlement to reduce future debt commitments. You can request from your bank or AKPK for a reduction in your monthly debt payments to free up more cash for living expenses. You should spend prudently on necessities only and not wants, and practice cash management, budgeting and debt management.</span>",
        },
      },
      result_non_Mortgage: {
        mark1: {
          analysis:
            "Your score of 10% means that you are using not more than the recommended limit of 15% of your net income to pay for your monthly commitment of unsecured debts.",
          recommendation:
            "<span>You must keep track of your expenses, spend prudently within your means, and continue to allocate funds to acquire liquid investment assets to meet expenses in the event of an emergency so that there is no need to obtain financing or loan to meet the unexpected expenses in order to maintain low debt ratio. </span>",
        },
        mark2: {
          analysis: "No parameter under Average.",
          recommendation: "<span>No parameter under Average.</span>",
        },
        mark3: {
          analysis:
            "Your score of 17.01% means that you are using more than the recommended limit of 15% of your net income to pay for your monthly commitment of unsecured debts.",
          recommendation:
            "<span>You should increase income and funds/savings to enable you to increase your debt instalment payments or to make early settlement to reduce future debt commitments. You can request from your bank or AKPK for a reduction in your monthly debt payments to free up more cash for living expenses. You should spend prudently on necessities only and not wants.",
        },
      },
      result_net_investment_assets: {
        mark1: {
          analysis:
            "Your score of 97.30% means that your net worth in the form of investment assets that are not financed by debt is higher than the recommended minimum of 50%.",
          recommendation:
            "<span>You must keep track of your expenses, spend prudently within your means, and continue to allocate funds to savings in order to acquire more investment assets so that there is no need to liquidate and deplete these assets in the event of an emergency in order to maintain high investment ratio.</span>",
        },
        mark2: {
          analysis: "No parameter under Average.",
          recommendation: "<span>No parameter under Average.</span>",
        },
        mark3: {
          analysis:
            "Your score of 30% means that your net worth in the form of investment assets that are not financed by debt is lower than the recommended minimum of 50%.",
          recommendation:
            "<span>You should increase income and savings so that you are able to acquire liquid investment assets to meet unexpected expenses, and to spend prudently on necessities only and not wants.</span>",
        },
      },
      result_solvency: {
        mark1: {
          analysis:
            "Your score of 70% means that your assets that are not financed by loans are higher than the recommended minimum of 50%. The higher the percentage of this ratio, the safer you are from the risk of bankruptcy.",
          recommendation:
            "<span>You must keep track of your expenses, spend prudently within your means, and continue to allocate funds to savings in order to acquire more investment assets so that there is no need to liquidate and deplete these assets in the event of an emergency in order to maintain high net worth.</span>",
        },
        mark2: {
          analysis: "No parameter under Average.",
          recommendation: "<span>No parameter under Average.</span>",
        },
        mark3: {
          analysis:
            "Your score of 30% means that your assets that are not financed by loans are lower than the recommended minimum of 50%. The lower the percentage of this ratio, the more you are at risk of bankruptcy.",
          recommendation:
            "<span>You should increase income and funds/savings to enable you to increase your debt instalment payments or to make early settlement, acquire investment and allocate more savings as this will reduce your total debts and increase your net worth. You should also spend prudently on necessities only and not wants. </span>",
        },
      },
    };

    const ratios_ms = {
      result_basic_liquidity_ratio: {
        mark1: {
          analysis:
            "Skor anda, iaitu 6.17 bulan bermakna, tanpa pendapatan, simpanan tunai anda mampu menampung perbelanjaan hidup anda selama 6.17 bulan, yakni lebih tinggi daripada rizab tunai yang disyorkan, iaitu sekurang-kurangnya 6 bulan.",
          recommendation:
            "<span>Anda mesti menjejaki perbelanjaan anda, berbelanja secara berhemat mengikut kemampuan anda, dan terus memperuntukkan dana kepada simpanan anda bagi menampung sebarang perbelanjaan luar jangka.</span>",
        },
        mark2: {
          analysis:
            "Skor anda, iaitu 4 bulan bermakna, tanpa pendapatan, simpanan tunai anda boleh menampung perbelanjaan hidup anda selama 4 bulan, yakni memenuhi rizab tunai untuk 3 bulan seperti yang disyorkan tetapi masih tidak cukup untuk memenuhi rizab tunai untuk 6 bulan. ",
          recommendation:
            "<span>Anda perlu meningkatkan pendapatan dan simpanan, berbelanja secara berhemat mengikut kemampuan dan mengamalkan pengurusan tunai dan bajet.</span>",
        },
        mark3: {
          analysis:
            "Skor anda, iaitu 2 bulan bermakna, tanpa pendapatan, simpanan tunai anda boleh menampung perbelanjaan hidup anda selama 2 bulan; ini jauh lebih rendah daripada rizab tunai yang disyorkan, iaitu sekurang-kurangnya 3 - 6 bulan.",
          recommendation:
            "<span>Anda perlu meningkatkan pendapatan dan simpanan untuk menampung perbelanjaan luar jangka, berbelanja secara berhemat untuk keperluan dan kehendak, mengurangkan perbelanjaan yang tidak perlu dan mengamalkan pengurusan tunai dan bajet. </span>",
        },
      },
      result_standby_liquidity_ratio: {
        mark1: {
          analysis:
            "Skor anda, iaitu 12.69 bulan bermakna, tanpa pendapatan, aset pelaburan cair anda yang boleh ditukar kepada tunai mampu menampung perbelanjaan hidup anda selama 12.69 bulan lagi selepas simpanan tunai anda selama ___ bulan (Nisbah Kecairan Asas) telah digunakan. Ini bermakna rizab tunai dan aset pelaburan cair anda boleh bertahan selama ______ bulan (Nisbah Kecairan Siap Sedia + Nisbah Kecairan Asas) untuk menampung perbelanjaan hidup anda.",
          recommendation:
            "<span>Anda perlu menjejaki perbelanjaan anda, berbelanja secara berhemat mengikut kemampuan anda, dan terus memperuntukkan dana kepada pemerolehan aset pelaburan cair bagi menampung sebarang perbelanjaan luar jangka.</span>",
        },
        mark2: {
          analysis:
            "Skor anda, iaitu 4 bulan bermakna, tanpa pendapatan, aset pelaburan cair anda yang boleh ditukar kepada tunai mampu menampung perbelanjaan hidup untuk 4 bulan lagi selepas simpanan tunai untuk ___bulan (Nisbah Kecairan Asas) telah habis digunakan. Ini bermakna rizab tunai dan aset pelaburan cair anda hanya boleh bertahan selama ___ bulan (Nisbah Kecairan Siap Sedia + Nisbah Kecairan Asas) untuk menampung perbelanjaan hidup anda.",
          recommendation:
            "<span>Anda perlu meningkatkan pendapatan dan simpanan supaya anda boleh menambah pelaburan aset cair untuk perbelanjaan luar jangka dan berbelanja secara berhemat untuk keperluan dan bukan kehendak.</span>",
        },
        mark3: {
          analysis:
            "Skor anda, iaitu 2 bulan bermakna, tanpa pendapatan, aset pelaburan cair anda yang boleh ditukar kepada tunai mampu menampung perbelanjaan hidup untuk 2 bulan lagi selepas simpanan tunai untuk ___bulan (Nisbah Kecairan Asas) telah habis digunakan. Ini bermakna rizab tunai dan aset pelaburan cair anda hanya boleh bertahan selama ___ bulan (Nisbah Kecairan Siap Sedia + Nisbah Kecairan Asas) untuk menampung perbelanjaan hidup anda.",
          recommendation:
            "<span>Anda perlu meningkatkan pendapatan dan simpanan supaya anda boleh menambah pelaburan aset cair untuk menampung perbelanjaan luar jangka, dan berbelanja secara berhemat untuk keperluan dan bukan kehendak. </span>",
        },
      },
      result_saving_ratio: {
        mark1: {
          analysis:
            "Anda mempunyai nisbah simpanan yang baik sebanyak 20.08%, yakni melebihi minimum yang disyorkan, iaitu 10% daripada pendapatan peribadi anda.",
          recommendation:
            "<span>Anda perlu menjejaki perbelanjaan anda, berbelanja secara berhemat mengikut kemampuan anda, dan terus memperuntukkan dana kepada pemerolehan aset pelaburan cair bagi menampung sebarang perbelanjaan luar jangka.</span>",
        },
        mark2: {
          analysis: "Tiada parameter untuk Sederhana",
          recommendation: "<span>Tiada parameter untuk Sederhana</span>",
        },
        mark3: {
          analysis:
            "Dengan nisbah simpanan 5%, ini bermakna simpanan anda tidak mencukupi kerana ia di bawah paras minimum yang disyorkan, iaitu 10%.",
          recommendation:
            "<span>Anda perlu meningkatkan pendapatan dan simpanan untuk menampung perbelanjaan luar jangka, berhemat ketika berbelanja untuk keperluan dan kehendak, dan mengurangkan perbelanjaan yang tidak perlu.</span>",
        },
      },
      result_liquid_assets: {
        mark1: {
          analysis:
            "Skor anda, iaitu 16.67% bermakna komposisi aset cair anda mencukupi kerana ia melebihi keperluan minimum, iaitu 15% untuk mempunyai kecairan yang cukup buat menampung perbelanjaan atau krisis luar jangka.",
          recommendation:
            "<span>Anda perlu menjejaki perbelanjaan anda, berbelanja secara berhemat supaya kekal mengikut kemampuan anda, dan mengekalkan serta terus memperuntukkan dana kepada pemerolehan aset pelaburan cair bagi menampung sebarang perbelanjaan luar jangka.</span>",
        },
        mark2: {
          analysis: "Tiada parameter untuk Sederhana",
          recommendation: "<span>Tiada parameter untuk Sederhana</span>",
        },
        mark3: {
          analysis:
            "Skor anda, iaitu 10% bermakna komposisi aset cair anda tidak mencukupi kerana ia berada di bawah paras minimum yang disyorkan, iaitu 15%, untuk mempunyai kecairan yang cukup buat menampung perbelanjaan atau krisis luar jangka.",
          recommendation:
            "<span>Anda perlu menambah pendapatan dan simpanan supaya anda mampu membeli aset pelaburan cair untuk menampung perbelanjaan luar jangka, dan berhemat dengan berbelanja hanya untuk keperluan dan bukan kehendak.</span>",
        },
      },
      result_debt: {
        mark1: {
          analysis:
            "Skor anda, iaitu 30% bermakna anda mempunyai lebih daripada aset yang mencukupi untuk dicairkan jika berlaku keadaan yang memerlukan anda menjual semua aset anda untuk membayar semua hutang anda.",
          recommendation:
            "<span>Anda perlu menjejaki perbelanjaan anda, berbelanja secara berhemat supaya kekal mengikut kemampuan anda, dan mengekalkan serta terus memperuntukkan dana kepada pemerolehan aset pelaburan cair untuk menampung perbelanjaan sekiranya berlaku kecemasan supaya tidak perlu mendapatkan pembiayaan atau pinjaman untuk memenuhi perbelanjaan yang tidak dijangka ini untuk mengekalkan nisbah hutang yang rendah. Anda perlu mengamalkan pengurusan tunai dan bajet.</span>",
        },
        mark2: {
          analysis:
            "Skor anda, iaitu 45% bermakna anda mempunyai aset yang lebih sedikit daripada mencukupi untuk dicairkan jika berlaku keadaan yang memerlukan anda menjual semua aset anda untuk membayar semua hutang anda.",
          recommendation:
            "<span>Anda perlu meningkatkan pendapatan dan dana/simpanan untuk membolehkan anda menambah bayaran ansuran hutang anda atau membuat penyelesaian awal kerana ini akan mengurangkan jumlah hutang anda. Anda perlu berbelanja secara berhemat mengikut kemampuan anda dan mengamalkan pengurusan tunai, bajet dan pengurusan hutang.</span>",
        },
        mark3: {
          analysis:
            "Skor anda, iaitu 60% bermakna anda tidak mempunyai aset yang mencukupi untuk dicairkan jika berlaku keadaan yang memerlukan anda menjual semua aset anda untuk membayar semua hutang anda.",
          recommendation:
            "<span>Anda perlu menambah pendapatan dan dana/simpanan untuk membolehkan anda menambah bayaran ansuran hutang anda atau membuat penyelesaian awal kerana ini akan mengurangkan jumlah hutang anda. Anda perlu berhemat dengan berbelanja untuk keperluan, bukan kehendak, dan mengamalkan pengurusan tunai, bajet dan pengurusan hutang.</span>",
        },
      },
      result_debt_service_ratio: {
        mark1: {
          analysis:
            "Skor anda, iaitu 35% bermakna anda anda menggunakan pendapatan bersih anda tidak lebih daripada had yang disyorkan, iaitu 40% untuk membayar komitmen hutang anda setiap bulan.",
          recommendation:
            "<span>Anda perlu menjejaki perbelanjaan anda, berbelanja secara berhemat mengikut kemampuan anda, dan terus memperuntukkan dana kepada pemerolehan aset pelaburan cair untuk menampung perbelanjaan sekiranya berlaku kecemasan supaya tidak perlu mendapatkan pembiayaan atau pinjaman untuk memenuhi perbelanjaan yang tidak dijangka ini untuk mengekalkan nisbah hutang yang rendah.</span>",
        },
        mark2: {
          analysis:
            "Skor anda, iaitu 50% bermakna anda menggunakan pendapatan bersih anda lebih daripada had yang disyorkan, iaitu 40% untuk membayar komitmen hutang anda setiap bulan.",
          recommendation:
            "<span>Anda perlu meningkatkan pendapatan dan dana/simpanan untuk membolehkan anda menambah bayaran ansuran hutang anda atau membuat penyelesaian awal untuk mengurangkan komitmen hutang masa hadapan. Anda boleh meminta bank anda atau AKPK untuk mengurangkan bayaran bulanan hutang anda supaya anda ada lebih wang tunai untuk perbelanjaan sara hidup. Anda perlu berhemat dengan berbelanja untuk keperluan dan bukan kehendak, dan mengamalkan pengurusan tunai, bajet dan pengurusan hutang.</span>",
        },
        mark3: {
          analysis:
            "Skor anda, iaitu 70% bermakna anda menggunakan pendapatan bersih anda lebih daripada had yang disyorkan, iaitu 40% untuk membayar komitmen hutang anda setiap bulan.",
          recommendation:
            "<span>Anda perlu menambah pendapatan dan dana/simpanan untuk meningkatkan bayaran hutang anda atau membuat penyelesaian awal untuk mengurangkan komitmen hutang masa hadapan. Minta bank anda atau AKPK untuk mengurangkan bayaran bulanan hutang anda supaya anda ada lebih wang tunai untuk perbelanjaan sara hidup. Anda perlu berhemat dengan berbelanja untuk keperluan dan bukan kehendak, dan mengamalkan pengurusan tunai, bajet dan pengurusan hutang. </span>",
        },
      },
      result_non_Mortgage: {
        mark1: {
          analysis:
            "Skor anda, iaitu 10% bermakna anda menggunakan pendapatan bersih anda tidak lebih daripada had yang disyorkan, iaitu 15% untuk membayar komitmen bulanan anda untuk hutang tidak bercagar.",
          recommendation:
            "<span>Anda perlu menjejaki perbelanjaan anda, berbelanja secara berhemat mengikut kemampuan anda, dan terus memperuntukkan dana kepada pemerolehan aset pelaburan cair untuk menampung perbelanjaan sekiranya berlaku kecemasan supaya tidak perlu mendapatkan pembiayaan atau pinjaman untuk memenuhi perbelanjaan yang tidak dijangka ini untuk mengekalkan nisbah hutang yang rendah.</span>",
        },
        mark2: {
          analysis: "Tiada parameter untuk Sederhana",
          recommendation: "<span>Tiada parameter untuk Sederhana</span>",
        },
        mark3: {
          analysis:
            "Skor anda, iaitu 17.01% bermakna anda menggunakan pendapatan bersih anda lebih daripada had yang disyorkan, iaitu 15% untuk membayar komitmen bulanan anda untuk hutang tidak bercagar.",
          recommendation:
            "<span>Anda perlu menambah pendapatan dan dana/simpanan untuk meningkatkan bayaran hutang anda atau membuat penyelesaian awal untuk mengurangkan komitmen hutang masa hadapan. Minta bank anda atau AKPK untuk mengurangkan bayaran bulanan hutang anda supaya anda ada lebih wang tunai untuk perbelanjaan sara hidup. Anda perlu berhemat dengan berbelanja untuk keperluan dan bukan kehendak.</span>",
        },
      },
      result_net_investment_assets: {
        mark1: {
          analysis:
            "Skor anda, iaitu 97.30% bermakna nilai harta bersih anda dalam bentuk aset pelaburan yang tidak dibiayai oleh hutang adalah lebih tinggi daripada minimum yang disyorkan iaitu 50%.",
          recommendation:
            "<span>Anda mesti menjejaki perbelanjaan anda, berbelanja secara berhemat mengikut kemampuan anda, dan terus memperuntukkan dana kepada simpanan untuk memperoleh lebih banyak aset pelaburan supaya tidak perlu mencairkan dan menggunakan aset ini sekiranya berlaku kecemasan agar nisbah pelaburan kekal tinggi.</span>",
        },
        mark2: {
          analysis: "Tiada parameter untuk Sederhana",
          recommendation: "<span>Tiada parameter untuk Sederhana</span>",
        },
        mark3: {
          analysis:
            "Skor anda, iaitu 30% bermakna nilai harta bersih anda dalam bentuk aset pelaburan yang tidak dibiayai oleh hutang adalah di bawah paras minimum yang disyorkan, iaitu 50%.",
          recommendation:
            "<span>Anda perlu menambah pendapatan dan simpanan supaya anda mampu membeli aset pelaburan cair untuk menampung perbelanjaan luar jangka dan berhemat dengan berbelanja hanya untuk keperluan  dan bukan kehendak.</span>",
        },
      },
      result_solvency: {
        mark1: {
          analysis:
            "Skor anda sebanyak 70% bermakna aset anda yang tidak dibiayai oleh pinjaman adalah lebih tinggi daripada minimum yang disyorkan iaitu 50%. Lebih tinggi peratusan nisbah ini, lebih selamat anda daripada risiko kebankrapan.",
          recommendation:
            "<span>Anda mesti menjejaki perbelanjaan anda, berbelanja secara berhemat mengikut kemampuan anda, dan terus memperuntukkan dana kepada simpanan untuk memperoleh lebih banyak aset pelaburan supaya tidak perlu mencairkan dan menggunakan aset ini sekiranya berlaku kecemasan agar nilai harta bersih anda kekal tinggi.</span>",
        },
        mark2: {
          analysis: "Tiada parameter untuk Sederhana",
          recommendation: "<span>Tiada parameter untuk Sederhana</span>",
        },
        mark3: {
          analysis:
            "Skor anda, iaitu 30% bermakna aset anda yang tidak dibiayai oleh pinjaman adalah di bawah paras yang disyorkan, iaitu 50%. Semakin rendah peratusan nisbah ini, semakin tinggi risiko anda untuk bankrap.",
          recommendation:
            "<span>Anda perlu menambah pendapatan dan dana/simpanan supaya anda dapat membayar ansuran hutang anda ayau membuat penyelesaian awal, mendapatkan pelaburan dan memperuntukkan lebih banyak simpanan kerana ini akan mengurangkan hutang keseluruhan dan meningkatkan nilai harta bersih anda. Anda juag perlu berhemat dengan berbelanja hanya untuk keperluan dan bukan kehendak.</span>",
        },
      },
    };

    const recommendations = [];
    const recommendation = "";
    const financialScoringReady = false;

    return {
      store,
      cstmr_id,
      fin_id,
      route,
      router,
      ratios,
      ratios_ms,
      recommendations,
      recommendation,
      cashEquivalance: 0,
      monthlyExpenses: 0,
      liquidInvestmentAssets: 0,
      StandbyMonthlyExpenses: 0,
      savingRationNetCashFlow: 0,
      savingRationGrossIncome: 0,
      liquidAssetsCashEquivalent: 0,
      liquidAssetsNetWorth: 0,
      debtTotalDebts: 0,
      debtTotalAssets: 0,
      debtServiceTotalMonthlyLoan: 0,
      debtServiceTotalMonthlyTake: 0,
      NetAnnualIncome: 0,
      TaxPayable: 0,
      NonMortgageLoanRepayment: 0,
      NonMortgageTakeHome: 0,
      netInvestmentAssets: 0,
      netInvestmentNetWorth: 0,
      solvencyTotalNetWorth: 0,
      solvencyTotalAssets: 0,
      financialScoringReady,
    };
  },

  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Financial Scoring", ["Customer"]);
    });
  },
  computed: {
    result_basic_liquidity_ratio: function () {
      let result = 0;
      result = this.cashEquivalance / this.monthlyExpenses;
      if (result) {
        return result.toFixed(2);
      } else {
        return 0;
      }
    },
    result_standby_liquidity_ratio: function () {
      let result_standby_liquidity_ratio = 0;
      result_standby_liquidity_ratio =
        this.liquidInvestmentAssets / this.monthlyExpenses;
      if (result_standby_liquidity_ratio) {
        return result_standby_liquidity_ratio.toFixed(2);
      } else {
        return 0;
      }
    },
    result_saving_ratio: function () {
      let result_saving_ratio = 0;
      let savingRationNetCashFlowPer = this.savingRationNetCashFlow;
      result_saving_ratio =
        (savingRationNetCashFlowPer / this.savingRationGrossIncome) * 100;
      if (result_saving_ratio) {
        return result_saving_ratio.toFixed(2);
      } else {
        return 0;
      }
    },
    result_liquid_assets: function () {
      let result_liquid_assets = 0;
      let liquidAssetsCashEquivalentPer = this.liquidAssetsCashEquivalent;
      result_liquid_assets =
        (liquidAssetsCashEquivalentPer / this.liquidAssetsNetWorth) * 100;
      if (result_liquid_assets) {
        return result_liquid_assets.toFixed(2);
      } else {
        return 0;
      }
    },
    // Debt-To-Assets Ratio
    result_debt: function () {
      let result_debt = 0;
      let debtTotalDebtsPer = this.debtTotalDebts * 100;
      result_debt = debtTotalDebtsPer / this.debtTotalAssets;
      if (result_debt) {
        return result_debt.toFixed(2);
      } else {
        return 0;
      }
    },
    //Debt Service Ratio
    result_debt_service_ratio: function () {
      let result_debt_service_ratio = 0;
      let debtServiceTotalMonthlyLoanPer =
        this.debtServiceTotalMonthlyLoan * 100;
      result_debt_service_ratio =
        debtServiceTotalMonthlyLoanPer / this.debtServiceTotalMonthlyTake;
      if (result_debt_service_ratio) {
        return result_debt_service_ratio.toFixed(2);
      } else {
        return 0;
      }
    },
    // result_debt_service_ratio_2: function () {
    //   let result_debt_service_ratio_2 = 0;
    //   let sub = this.NetAnnualIncome - this.TaxPayable;
    //   result_debt_service_ratio_2 = sub / 12;
    //   if (result_debt_service_ratio_2) {
    //     return result_debt_service_ratio_2.toFixed(2);
    //   } else {
    //     return 0;
    //   }
    // },

    // Non-Mortgage Debt Service Ratio
    result_non_Mortgage: function () {
      let result_non_Mortgage = 0;
      let NonMortgageLoanRepaymentPer = this.NonMortgageLoanRepayment * 100;
      result_non_Mortgage =
        NonMortgageLoanRepaymentPer / this.NonMortgageTakeHome;
      if (result_non_Mortgage) {
        return result_non_Mortgage.toFixed(2);
      } else {
        return 0;
      }
    },
    result_net_investment_assets: function () {
      let result_net_investment_assets = 0;
      let netInvestmentAssetsPer = this.netInvestmentAssets * 100;
      result_net_investment_assets =
        netInvestmentAssetsPer / this.netInvestmentNetWorth;
      if (result_net_investment_assets) {
        return result_net_investment_assets.toFixed(2);
      } else {
        return 0;
      }
    },
    result_solvency: function () {
      let result_solvency = 0;
      let solvencyTotalNetWorthPer = this.solvencyTotalNetWorth * 100;
      result_solvency = solvencyTotalNetWorthPer / this.solvencyTotalAssets;
      if (result_solvency) {
        return result_solvency.toFixed(2);
      } else {
        return 0;
      }
    },
  },
  methods: {
    init() {
      this.financialScoringReady = true;
    },
    getHumanDate(date) {
      console.log(date);
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },

    onSubmit(value) {
      this.recommendations.push(this.recommendation);

      const data = {
        id: this.fin_id,
        user_id: this.cstmr_id,
        created_by: this.store.getters.currentUser.id,
        financial_statement_type_id: 4,
        raw: {
          cashEquivalance: this.cashEquivalance,
          monthlyExpenses: this.monthlyExpenses,
          liquidInvestmentAssets: this.liquidInvestmentAssets,
          StandbyMonthlyExpenses: this.StandbyMonthlyExpenses,
          liquidAssetsCashEquivalent: this.liquidAssetsCashEquivalent,
          debtTotalDebts: this.debtTotalDebts,
          debtTotalAssets: this.debtTotalAssets,
          debtServiceTotalMonthlyLoan: this.debtServiceTotalMonthlyLoan,
          debtServiceTotalMonthlyTake: this.debtServiceTotalMonthlyTake,
          NonMortgageLoanRepayment: this.NonMortgageLoanRepayment,
          NonMortgageTakeHome: this.NonMortgageTakeHome,
          netInvestmentAssets: this.netInvestmentAssets,
          solvencyTotalNetWorth: this.solvencyTotalNetWorth,
          solvencyTotalAssets: this.solvencyTotalAssets,
          recommendation: this.recommendation,
        },
      };

      if (this.fin_id) {
        this.store
          .dispatch(Actions.UPDATE_FINANCIAL_STATEMENT, data)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Financial Scoring has been updated!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.router.push({
                path: `/customers/details/${this.cstmr_id}/financial-scoring-hh/list`,
              });
            });
          });
      } else {
        this.store
          .dispatch(Actions.STORE_FINANCIAL_STATEMENT, data)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Financial Scoring has been saved!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.router.push({
                path: `/customers/details/${this.cstmr_id}/financial-scoring-hh/list`,
              });
            });
          });
      }
    },
    onPrint() {
      window.print();
    },
  },
  async mounted() {
    this.init();
  },
});
